@import "@styles/variants.scss";

.Modal {
  background: rgba(48, 48, 48, 0.84);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  text-align: left;
  top: 0;
  transition: opacity 0.25s ease;
  z-index: 11;
}

.Modal__Background {
  @include undoDefaultButton;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.Modal__Container {
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 0 24px;
}

.Modal__Inner {
  align-items: center;
  background: $white;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  left: 0;
  margin: auto;
  max-height: 80vh;
  max-width: 660px;
  opacity: 1;
  overflow: auto;
  padding: 60px 2em 1em;
  position: absolute;
  right: 0;
  top: 20%;
  transition: top 0.25s ease;
  width: 90%;

  @include media($medium) {
    top: 10%;
    padding-top: 30px;
  }

  @include media($small) {
    padding: 30px 12px 12px;
  }
}

.Modal__Close {
  @include undoDefaultButton;
  cursor: pointer;
  height: 1.1em;
  margin: none;
  position: absolute;
  right: 1.5em;
  top: 1.5em;
  width: 1.1em;

  @include media($medium) {
    right: 0.7em;
    top: 0.7em;
  }
}

.Modal__Close:after,
.Modal__Close:before {
  background: $black;
  content: "";
  display: block;
  height: 1.5em;
  left: 50%;
  margin: -3px 0 0 -1px;
  position: absolute;
  top: 0;
  transform: rotate(45deg);
  width: 2px;
}

.Modal__Close:hover:after,
.Modal__Close:hover:before {
  background: $medium-grey;
}

.Modal__Close:before {
  transform: rotate(-45deg);
}

.Modal__Header {
  @include defaultH3;
  color: $primary-dark;
  font-size: 1.571rem;
  margin: grid(2) 0;
  text-align: center;

  @include media($medium) {
    .Modal__Header {
      font-size: 1rem;
    }
  }
}

.Modal__Button {
  @include inverse-button($primary-dark);
  margin: grid(4);
}

.Modal hr {
  border-top: solid 1px $primary-dark;
  height: 0px;
  width: 60%;
}

// allow snackbar to overlay zendesk launcher
.Snackbar__Root {
  z-index: 1000000 !important;
}
