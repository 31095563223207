$inter-font-family: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif;
$playfair-font-family: "Playfair Display", serif;

@mixin font-light {
  font-family: $inter-font-family;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
}

@mixin font-regular {
  font-family: $inter-font-family;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

@mixin font-medium {
  font-family: $inter-font-family;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}

@mixin font-semi-bold {
  font-family: $inter-font-family;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
}

@mixin font-bold {
  font-family: $inter-font-family;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
}

@mixin font-regular-italic {
  font-family: $inter-font-family;
  font-style: italic;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

@mixin font-bold-italic {
  font-family: $inter-font-family;
  font-style: italic;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
}

@mixin font-2-regular {
  font-family: $playfair-font-family;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

@mixin font-2-bold {
  font-family: $playfair-font-family;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}

@mixin font-2-black {
  font-family: $playfair-font-family;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
}
