@mixin shimmer {
  animation : shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

@mixin transparent-shimmer {
  animation : shimmer 2s infinite linear;
  background: linear-gradient(to right, rgba(239, 241, 243, 0.05) 4%, rgba(226, 226, 226, 0.1) 25%, rgba(239, 241, 243, 0.05) 36%);
  background-size: 1000px 100%;
}
