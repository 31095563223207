@import "@styles/variants";

.Banner {
  background-color: $accent-light-alt;
  position: unset;
  text-align: right;

  @include media($large) {
    width: 100%;
    z-index: 6;
  }
}

.Banner_Inner {
  @include defaultBodyText;
  @include font-medium;
  color: $white;
  line-height: 1.3;
  padding: grid(2) grid(3.5);

  @include media($large) {
    padding: grid(1) grid(2);
  }

  @include media($medium) {
    padding: grid(1);
  }

  a {
    @extend .Banner_Inner;
    @include font-bold;
    padding: 0;
    text-decoration: underline;
    text-transform: uppercase;

    &:hover {
      opacity: 0.8;
    }
  }
}
