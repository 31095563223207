@import "@styles/variants";

.ImpersonateBanner__Wrapper {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999999;

  @include media($medium) {
    top: 45px;
  }
}

.Banner.ImpersonateBanner {
  background-color: $red;

  .Banner_Inner {
    font-size: 0.9rem;
    line-height: 1;
    padding: grid(0.5) grid(3);

    @include media($medium) {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      line-height: 1.3;
    }
  }
}

.ImpersonateBanner__StopImpersonateButton {
  @include inverse-button($red);
  margin-left: grid(2);
  padding: 0 grid(1);

  @include media($small) {
    display: block;
    float: right;
  }
}
