@import "@styles/variants";

.Avatar__Wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 300px;
  position: relative;
}

.Avatar {
  border-radius: 50%;
  height: auto;

  &.user {
    background-color: $accent-light-alt;
    border: 5px solid $accent-light-alt;
  }

  &.admin,
  &.staff {
    background-color: $black;
    border: 5px solid $black;
  }

  &.power_host {
    background-color: $accent-dark-alt;
    border: 5px solid $accent-dark-alt;
  }

  &.cl,
  &.host,
  &.moderator {
    background-color: $primary-dark;
    border: 5px solid $primary-dark;
  }
}

.Avatar__Badge {
  @include font-bold;

  background-color: $accent-light-alt;
  bottom: 0;
  color: $white;
  font-size: 1.143rem;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: normal;
  min-width: 200px;
  padding: grid(1) grid(4.5);
  position: absolute;
  text-align: center;
  text-transform: uppercase;

  &.admin,
  &.staff {
    background-color: $black;
  }

  &.power_host {
    background-color: $accent-dark-alt;
  }

  &.cl,
  &.host,
  &.moderator {
    background-color: $primary-dark;
  }
}
