.MutationButton {
  position: relative;
}

.MutationButton__Loading {
  padding-left: 36px;
  padding-right: 12px;
}

.MutationButton__ButtonSpinner {
  display: block;
  position: absolute;
  height: 16px;
  width: 16px;
  top: calc(50% - 8px);
  left: 12px;
}
