@import "@styles/variants";

.Header__Link_Nav.HeaderDropdown__Button {
  @include undoDefaultButton;
  margin: unset;
  position: relative;

  svg {
    path {
      stroke: $white;
    }
  }
}

.Header__MobileCTAWrapper .Header__Link_Nav.HeaderDropdown__Button {
  min-height: grid(8);
  padding-top: 0;
  padding-right: 0;
  margin-right: grid(2);

  @include media($large) {
    margin-right: grid(4);
  }
}

.HeaderDropdown__Icon {
  vertical-align: middle;
}

.HeaderDropdown__Title {
  @include font-bold;
  color: $primary-dark;
  font-family: $playfair-font-family;

  @include media($large) {
    margin-bottom: 0;
  }
}

.HeaderDropdown__MarkAllAsRead {
  @include undoDefaultButton;
  @include font-semi-bold;
  color: $accent-dark;
  font-size: 1.125rem;
}

.HeaderDropdown__Container {
  @include default-shadow;

  &:after {
    content: "";
    border-bottom: 10px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: 44px;
    left: 0;
  }
}

.HeaderDropdown__Container .HeaderDropdown__Inner {
  @include default-border;
  background-color: $white;
  border-left: none;
  border-right: none;
  border-top: none;
  color: $primary-dark;
  left: -430px;
  max-height: 80vh;
  min-width: grid(80.5);
  overflow-y: scroll;
  padding: grid(5) 0 0;
  position: absolute;
  top: 53px;
  z-index: 5;
}

.HeaderDropdown__Inner {
  @include default-shadow;
  max-height: 85vh;
  overflow-y: scroll;
}

.HeaderDropdown__FetchMore {
  position: relative;
  bottom: grid(18);
}

.HeaderDropdown__Notification {
  @include default-border;
  align-items: center;
  border-top: none;
  color: $dark-grey;
  display: flex;
  justify-content: flex-start;
  padding: grid(2) grid(3.3);
  width: 100%;
  font-size: 14px;

  &--Unread {
    background-color: transparentize($accent-light-alt, 0.8);

    .HeaderDropdown__Time {
      color: $accent-dark;
    }
  }

  &:hover {
    color: $dark-grey;
    background-color: transparentize($accent-light-alt, 0.9);
  }

  &:last-child {
    border-bottom: none;
  }

  @include media-greater-than($large) {
    font-size: 18px;
  }
}

.HeaderDropdown__Content {
  display: flex;
  flex-direction: column;
}

.HeaderDropdown__UnreadDot {
  display: inline-block;
  background-color: $accent-dark;
  border-radius: 50%;
  height: 6px;
  max-height: 8px;
  max-width: 8px;
  min-height: 8px;
  min-width: 8px;
  width: 6px;
}

.HeaderDropdown__Message {
  display: inline-block;
  padding-right: grid(2);
}

.HeaderDropdown__Time {
  font-size: 13px;
  display: inline-block;
  margin-right: grid(1.2);

  @include media-greater-than($large) {
    font-size: 17px;
  }
}

.HeaderDropdown__Header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  @include media-greater-than($large) {
    align-items: center;
  }

  h4 {
    font-size: 28px;

    @include media-greater-than($large) {
      font-size: 31px;
    }
  }
}

.HeaderDropdown__Header,
.HeaderDropdown__SectionTitle,
.HeaderDropdown__NoResults,
.HeaderDropdown__Footer {
  padding: grid(4.1) grid(3.8) 0;

  @include media-greater-than($large) {
    padding: grid(1) grid(3.3) 0;
  }
}

.HeaderDropdown__SectionTitle {
  @include font-semi-bold;
  font-size: 16px;
  padding-bottom: grid(3.6);
  padding-top: grid(4.3);
  margin: 0;

  @include media-greater-than($large) {
    font-size: 18px;
    padding-bottom: grid(2.8);
  }
}

.HeaderDropdown__Footer {
  @include font-semi-bold;
  text-align: center;
  padding: grid(3) 0 grid(3) 0;
  font-size: 18px;
  box-shadow: 0px -4px 4px rgb(0 0 0 / 3%);
  position: sticky;
  bottom: 0;
  background-color: white;
}

.HeaderDropdown__FooterLink {
  color: $accent-dark;
}

.HeaderDropdown__Notification__Avatar {
  margin-right: grid(2);
}

.HeaderDropdown__NoResults {
  @include defaultBodyText;
  display: inline-block;
  padding-bottom: grid(2.8);
}
