@mixin defaultLink {
  @include font-regular;

  color: $primary-dark;
  font-size: inherit;
  line-height: inherit;
  opacity: 1;
  text-decoration: underline;
  transition: opacity 200ms ease 0ms, color 200ms ease 0ms;

  &:hover {
    color: $accent-light-alt;
    cursor: pointer;
    opacity: 1;
    text-decoration: underline;
  }

  &:focus,
  &:active {
    color: $accent-light-alt;
    opacity: 1;
  }
}

@mixin heroTagline {
  @include font-regular;

  color: $medium-grey;
  font-size: 1.857rem; // 26px
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;

  @include media($medium) {
    font-size: 1.429rem; // 20px
  }
}

@mixin photoCaption {
  @include font-regular-italic;

  font-size: 1rem;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 2;

  @include media($medium) {
    font-size: 0.75rem; // 12px
  }
}

@mixin defaultH1 {
  @include font-2-black;

  color: $medium-grey;
  font-size: 3.75rem; // 60px
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.17;

  @include media($medium) {
    font-size: 3.375rem; // 54px
  }
}

@mixin defaultH2 {
  @include font-bold;

  color: $medium-grey;
  font-size: 2.5rem; // 40px
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;

  @include media($medium) {
    @include font-regular;
    font-size: 2.25rem; // 36px
  }

  @include media($mobile) {
    font-size: 2rem; // 32px
  }
}

@mixin defaultH3 {
  @include font-2-black;

  color: $medium-grey;
  font-size: 1.875rem; // 30px
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.53;

  @include media($medium) {
    font-size: 1.25rem; // 20px
    line-height: 1.5;
  }
}

@mixin defaultH4 {
  @include font-bold;

  color: $medium-grey;
  font-size: 1rem; // 16px
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.23px;
  line-height: 2.25;
  text-transform: uppercase;

  @include media($medium) {
    font-size: 1rem; // 16px
    letter-spacing: 1.2px;
    line-height: 1.5;
  }
}

@mixin defaultH5 {
  @include font-semi-bold;
  color: $medium-grey;
  font-size: 0.75rem; // 12px
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: 1.67;
  text-transform: uppercase;

  @include media($medium) {
    line-height: 2;
  }
}

@mixin defaultBodyText {
  @include font-regular;

  color: $medium-grey;
  font-size: 1rem; // 16px
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
}

@mixin defaultOrderedListItem {
  @include defaultBodyText;

  margin: grid(2.5) grid(4) grid(2.5) grid(-2.5);
  padding-left: grid(2);
}

@mixin defaultUnorderedListItem {
  @include defaultBodyText;

  margin: grid(2.5) grid(4) grid(2.5) grid(-2.5);
  padding-left: grid(2);
}

@mixin defaultDate {
  @include font-semi-bold;
  color: $medium-grey;
  font-size: 0.75rem;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: 1.67;
  text-transform: uppercase;
}
