@import "@styles/variants";

.ErrorBoundary__Container {
  margin: 60px auto;
  max-width: 600px;
  text-align: center;

  @include media($small) {
    padding: 12px;
  }
}

.ErrorBoundary__Hero {
  @include defaultH2;
}

.ErrorBoundary__Wrapper {
  padding: grid(2);
}

.ErrorBoundary__Backup {
  @include defaultBodyText;
}

.ErrorBoundary__Text {
  @include defaultBodyText;
  font-size: 1.286rem;
  margin-top: grid(5);

  a {
    @include defaultLink;
  }
}

.ErrorBoundary__Button {
  @include button($primary-dark);
  margin-left: grid(3);
}

.ErrorBoundary__ButtonLink {
  @include linkButton;
  padding: 0;
}
