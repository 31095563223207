@import "@styles/variants";

.Spinner__Wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: -15px;
  margin-right: -15px;
  width: 100%;
}

.Spinner__Border {
  animation: spinner-border 0.75s linear infinite;
  border-radius: 50%;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  color: $accent-dark;
  display: inline-block;
  height: 2rem;
  vertical-align: text-bottom;
  width: 2rem;
}

.Spinner__Message {
  @include defaultBodyText;
  margin-top: grid(3);
}
