@import "@styles/variants";

.Container {
  padding: grid(4) grid(2.7);
  margin-bottom: grid(3.6);
  @include media-greater-than($large) {
    padding: grid(8) grid(26.5);
    margin-bottom: grid(0);
  }
}

.Header, .NoResults{
  display: flex;
  flex-direction: column;
  margin-top: grid(3.3);
  padding-bottom: grid(4.8);
  padding-left: grid(1.8);

  @include media-greater-than($large) {
    margin-top: grid(0);
    padding-left: grid(0);
  }
  @include media-greater-than($mobile) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.Title {
  @include font-bold;
  color: $primary-dark;
  font-family: $playfair-font-family;
  font-size: 28px;
  margin: 0;

  @include media-greater-than($large) {
    font-size: 41.5px;
  }
}

.MarkAllAsRead {
  @include undoDefaultButton;
  @include font-semi-bold;
  color: $accent-dark;
  font-size: 1rem;
  padding-top: 6px;

  @include media-greater-than($mobile) {
    padding-top: auto;
    font-size: 1.125rem;
  }
}

.Section {
  padding-bottom: grid(4.5);
}

.SectionTitle {
  @include font-semi-bold;
  font-size: 16px;
  padding-bottom: grid(2.8);
  padding-left: grid(1.8);
  margin: 0;

  @include media-greater-than($large) {
    font-size: 23.682px;
    padding-bottom: grid(3.2);
    padding-left: 0px;
  }
}

.Section :global(.HeaderDropdown__Notification) {
  border: none;
  border-top: 0.5px solid rgba(140, 138, 135, 0.2);
  border-bottom: 0.5px solid rgba(140, 138, 135, 0.2);
  padding-left: grid(1.8);
}

.ViewMore {
  @include linkButton;
  @include font-semi-bold;
  width: 100%;
  text-align: center;
  padding-top: grid(3);
  font-size: 14px;
  color: $accent-dark;

  svg {
    height: 12px;
    margin-left: grid(2);
    width: 12px;
  }

  @include media-greater-than($large) {
    padding-top: grid(5);
    font-size: 18px;
  }
}

.Inner :global(.BackButton) {
  position: relative;
  left: 0;
  top: 0;
  border: none;
  background-color: transparent;
  color: $primary-dark;
  padding-left: grid(1.8);
}

