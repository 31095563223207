// Color names are from https://chir.ag/projects/name-that-color/#6195ED
// update Colors.js as well if these are changed

$primary-dark: #31122c; // dark-purple
$primary-light: #f6f0ea; // sand
$accent-light: #f6f9d9; // lemon
$accent-light-alt: #e5866b; // salmon
$accent-dark: #7c0634; // bordeaux
$accent-dark-alt: #08aaaa; // turquoise
$faint-grey: #fbf9f7;
$athens-grey: #e6e8ed;
$light-grey: #8c8a87;
$medium-grey: #4a4a4a;
$dark-grey: #303030;

$white: #ffffff;
$black: #000000;

// Only use for error messages.
$red: #e44a53;
$green: #54a12e;
