@import "shared/fonts";
@import "shared/colors";

.scale-children {
  @media only screen and (max-width: 992px) {
    font-size: 100%;
  }
  @media only screen and (max-width: 768px) {
    font-size: 85%;
  }
  @media only screen and (max-width: 576px) {
    font-size: 70%;
  }
}

.txt-small {
  font-size: 1rem; // 16 px
}

.txt-heavy {
  font-weight: 700;
}

// Font
.txt-roboto {
  @include font-regular;
}

// Color

.txt-lite-gray {
  color: $faint-grey;
}

.txt-primary {
  color: $medium-grey;
}

.txt-secondary {
  color: $red;
}

.txt-blue {
  color: $primary-dark;
}

.txt-error {
  color: $red;
}

.txt-aside {
  font-weight: 300;
  color: $medium-grey;
  font-style: italic;
  font-size: 14px;
}
