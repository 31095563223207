// TODO: go through this file carefully and delete
// or relocate everything

@import "@styles/variants.scss";

html,
body {
  height: 100%;
}

html {
  @include font-regular;
  font-size: 16px;
}

* {
  outline: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

.img-fluid {
  object-fit: cover;
}

.row.no-gutters {
  margin-left: -15px;
  margin-right: -15px;
}

// All form labels are identically colored
label.form-label {
  @include font-medium;
  color: $medium-grey;
}

.form-check ~ .form-label {
  @include font-regular;
  color: $medium-grey;
}

.form-control.is-invalid {
  background-image: unset;
  padding-right: 1rem;

  &:active,
  &:focus,
  &:focus-within {
    border-color: $accent-light;
    box-shadow: none;
  }
}

// Form controls should have thicker borders and a soft pink outline per the style guide
.form-control-lg {
  @include defaultBodyText;
  border: 1px solid transparentize($light-grey, 0.7);
  border-radius: 0;
  height: 100%;

  &:active,
  &:focus,
  &:focus-within {
    border-color: $accent-light;
    box-shadow: unset;
  }
  &:disabled {
    background-color: $faint-grey;
    color: $black;
    opacity: 0.5;
  }
}

.btn.focus,
.btn:focus,
.btn.active {
  outline: unset;
  box-shadow: unset;
}

.dropdown-item:focus,
.dropdown-item:hover {
  text-decoration: none;
  background-color: $accent-light;
  color: $black !important;
}

// List should be above footer rather than cut off.
.list-group {
  z-index: 3 !important;
}

// Active list group items must be recolored per style guide
.list-group-item.active {
  background-color: $accent-light;
  border-color: $accent-light;
  color: unset;
}

// Tooltip inner content styling overrides
.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  @include default-shadow;
  @include defaultBodyText;
  background-color: $faint-grey;
  color: $dark-grey;
  font-size: 0.8rem;
  max-width: 350px;
  padding: 1rem;
  text-align: left;
}

.tooltip.bs-tooltip-bottom .arrow:before {
  display: none;
}

// Modal overrides for profile preview
.modal.preview .modal-content {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: scroll;
}

.modal.preview {
  transition: none;

  .modal-dialog {
    transition: none;
  }
}

.user-rich-text {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $inter-font-family;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  h2 {
    font-size: 1.4rem;
    font-weight: 600;
  }

  h3 {
    font-size: 1.4rem;
    font-weight: 500;
  }

  h4 {
    font-size: 1.25rem;
  }

  h5 {
    font-size: 1.2rem;
  }

  p {
    margin-bottom: 0;
  }

  img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
}

// Rich text styles
.ql-toolbar.ql-snow {
  border-radius: 4px 4px 0 0 !important;
  border-width: 2px !important;
  border-color: #ced4da !important;
}

.ql-container.ql-snow {
  &.rich-text-description,
  &.rich-text-logistics {
    height: auto !important;
    border: 2px solid #ced4da;
    border-radius: 0 0 4px 4px;
    overflow: visible;
  }

  &.rich-text-description .ql-editor {
    min-height: 225px;
  }

  &.rich-text-logistics .ql-editor {
    min-height: 150px;
  }
}

// Needed to target the dropdown arrow
.dropdown-toggle::after {
  margin-left: auto;
  display: none;
}

button.form-border {
  @include default-border;
  @include defaultBodyText;
  border-radius: 0;
}

.dob-menu {
  max-height: 300px;
  overflow-y: scroll;
}

.dob-invalid {
  border-color: #dc3545;
}

.drop-width {
  min-width: 100%;
}

.tz-drop {
  max-height: 285px;
  overflow-y: scroll;
}

.filter-width {
  min-width: 150px;
}

//Chevron color overrides
.chevron-drop {
  margin-left: auto;
  color: $medium-grey;
}

.chevron-gray {
  margin-left: auto;
  color: $medium-grey;
}

.chevron-link {
  color: $primary-dark;
}

.chevron-white {
  color: white;
}

//"Post Like Heart" variant color - gold
.heart-golden {
  color: $accent-dark;
}

// Checkmarks for profile completion
.check-green {
  color: $green;
}

//navbar padding needs to be 0 for profilepic drop to fit on mobile
.navbar {
  padding: 0px;
}

.nav-drop {
  width: 350px;
}

.profileActionsMobile {
  position: relative;
  margin-top: 15px;

  & > img {
    position: absolute;
    top: 5px;
    right: 20px;
  }
}

#client-snackbar {
  color: white;

  svg {
    fill: white;
    margin-top: -2px;
  }
}

// RSVP Modal Overrides
.modal-90w {
  max-width: 90%;
}

@media screen and (max-width: 576px) {
  .modal-90w {
    max-width: 100%;
  }
}

.modal-content {
  border-radius: 0;
  margin: auto;
  max-width: 992px;
  min-height: 80%;
}

.modal-body {
  // Padding overrides to allow row/column nesting without overflow
  padding-left: 15px;
  padding-right: 15px;
}

@media screen and (max-width: 767px) {
  .modal-content {
    max-width: 100%;
    height: calc(100vh - 1rem);
  }

  .modal-body {
    max-height: 100%;
    overflow: scroll;
  }
}

// Rate Your Event Modal overrides
.stars .dv-star-rating-empty-star i {
  color: rgb(208, 207, 207);
  font-size: 3.5rem;
  cursor: pointer;
  line-height: 1;

  @include media($mobile) {
    font-size: 3rem;
  }
}

.stars .dv-star-rating-full-star i {
  color: #ffb400;
  cursor: pointer;
  font-size: 3.5rem;
  line-height: 1;

  @include media($mobile) {
    font-size: 3rem;
  }
}

// event details overrides
.eventDetailInput {
  max-width: 300px;
  padding: 0.8rem;

  span {
    margin-right: 12px;
    margin-top: 3px;
  }
}

.icon-blue {
  color: #4178b3;
}

.no-gap {
  margin-top: -20px;
}

.Header__Mobile_Container .Header__Mobile_Collapse--open {
  border-bottom: 1px solid $border-color;
  margin-bottom: 0;
}

// April Fools Day START
#root .AprilFools .Header__Brand {
  animation: rotation 2s linear 5;
  transition: fill 1s;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
    fill: $white;
  }
  to {
    transform: rotate(359deg);
    fill: $accent-dark;
  }
}
// April Fools Day END

// Filestack Picker Override for Mobile
.fsp-picker {
  @include media($medium) {
    top: 60px !important;
  }
}
