@import "shared/colors";

.input-tooltip {
  position: relative;
  top: 6px;
}

.terms-check {
  cursor: pointer;

  .checkmark {
    height: 25px;
    width: 25px;
    border-radius: 50px;
    border: 1px solid $accent-dark;
    flex-shrink: 0;

    &.error {
      border-color: $red;
    }
  }

  .checkmark::after {
    content: "";
    position: relative;
    display: none;
    left: 8px;
    top: 3px;
    width: 7px;
    height: 14px;
    border: solid $accent-dark;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .checkmark.checked::after {
    display: block;
  }
}
