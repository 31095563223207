@import "@styles/variants";

.LoadingImage {
  position: relative;
}

.IconWrapper {
  background: transparentize(#ffffff, 0.5);
  border-radius: 50%;
  padding: grid(2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Icon {
  height: 30px;
  width: 30px;
}
