@mixin default-shadow {
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
}

@mixin highlight-shadow {
  box-shadow: 4px 4px 20px 0 transparentize($accent-light-alt, 0.1);
}

@mixin default-border {
  border: 1px solid transparentize($light-grey, 0.8);
}

$border-color: transparentize($light-grey, 0.8);

@mixin word-wrap {
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: pre-wrap; /* Since CSS 2.1 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
}

/**
 Use this on interactive elements & buttons to keep user from selecting them.
 Especially important when using long press gestures!
 */
@mixin user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/**
 Use this instead of "display: none" when hiding inputs to display something
 custom, this is a11y-friendly.
 */
@mixin hide-input {
  height: 40px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 40px;
}

@mixin mobile-filter-background {
  background-color: transparentize($black, 0.5);
  height: 100vh;
  left: -6px;
  position: absolute;
  top: -78px;
  width: 100vw;
}

@mixin unfilled-rating-star {
  font-size: 14px;
  margin: grid(1) grid(1) grid(1) 0;
  stroke: $black;
  stroke-width: 10px;
}
