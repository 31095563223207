@import "shared/colors";

.icon-base-med {
  color: $medium-grey;
}

.icon-hover {
  &.insta:hover::before,
  &:active::before,
  &:focus-within::before {
    color: $accent-light;
  }

  &.facebook:hover::before,
  &:active::before,
  &:focus-within::before {
    color: $accent-light;
  }

  &.email:hover::before,
  &:active::before,
  &:focus-within::before {
    color: $accent-light;
  }

  &.twitter:hover::before,
  &:active::before,
  &:focus-within::before {
    color: $accent-light;
  }
}
