$transitionSpeed: 150ms;

@mixin buttonBase() {
  @include font-medium;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: normal;
  outline: none;
  padding: 8.5px grid(4);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color $transitionSpeed ease-in,
    color $transitionSpeed ease-in;

  span,
  svg {
    font-size: 1rem;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.2px;
    line-height: normal;
  }

  &[disabled] {
    @include default-border;
    background-color: transparentize($light-grey, 0.7);
    color: $medium-grey;
    cursor: default;
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }

  @include media($medium) {
    font-size: 0.9rem;
    padding: grid(1.5) grid(4);

    span,
    svg {
      font-size: 0.9rem;
    }
  }
}

@mixin hoverState($txt-color, $bg-color: $faint-grey) {
  background-color: $bg-color;
  border: 1px solid $txt-color;
  color: $txt-color;
  text-decoration: none;
}

@mixin button($bg-color, $txt-color: $white) {
  @include buttonBase;

  background-color: $bg-color;
  border: 1px solid $bg-color;
  color: $txt-color;
  transition: all $transitionSpeed ease-in;

  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    @include hoverState($primary-dark);
  }
}

@mixin inverse-button($txt-color, $bg-color: $white) {
  @include buttonBase;

  background-color: $bg-color;
  border: 1px solid $txt-color;
  color: $txt-color;
  transition: all $transitionSpeed ease-in;

  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    @include hoverState($primary-dark);
  }
}

// redundant as we have the buttonBase use the same values.
@mixin limitedUseButton {
  font-size: 1rem;
  padding: 8.5px grid(4);

  @include media($medium) {
    padding: grid(1) grid(2);
  }
}

/**
 Button that looks like a link
 Provides better accessibility and should be used for everything
 that doesn't link to a new page, i.e. modal toggle button, etc.
*/
@mixin linkButton {
  @include defaultLink;

  background-color: transparent;
  border: 0;

  &:focus {
    outline: 0;
  }
}

@mixin textButton {
  background-color: transparent;
  border: 0;

  &:focus {
    outline: 0;
  }
}

// If you want a button component but you don't want it to
// be styled like a button, use this mixin
@mixin undoDefaultButton {
  background-color: inherit;
  border: unset;
  outline: 0;
  text-align: unset;
  padding: unset;

  &:focus {
    outline: unset;
  }
}
