@import "@styles/variants";

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: grid(5);
}

.LoginButton {
  @include undoDefaultButton;
  @include button($accent-dark);
  margin: grid(1);
}

.Prompt {
  margin: 0 grid(1) grid(2);
}
