@import "@styles/variants";

// Allows body to take the notches full screen width in landscape
// More info here: https://bubblin.io/blog/notch
@supports (padding: max(0px)) {
  body,
  header,
  footer {
    padding-left: min(0vmin, env(safe-area-inset-left));
    padding-right: min(0vmin, env(safe-area-inset-right));
  }
}

.Layout {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "main";
  height: 100vh;

  @include media($large) {
    grid-template-areas:
      "header"
      "main"
      "footer";
  }
}

.Layout header {
  grid-area: header;
}

.Layout main {
  background-color: $faint-grey;
  display: grid;
  grid-area: main;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior-x: none;
  padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom)
    env(safe-area-inset-left);
  position: relative;
}

.MainSection {
  width: 100vw;
}

.Layout footer {
  grid-area: 2 / 1 / 3 / 4;
}

.Layout footer.Mobile {
  grid-area: footer;
}
