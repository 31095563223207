@import "@styles/variants";

$larger: 1212px;
$mobile-header-height: 48px;
$tablet-header-height: 72px;

#myLauncher {
  background-color: red;
  border-radius: 4px;
  color: white;
  font-family: sans-serif;
  padding: 10px;
  position: fixed;
  right: 0;
  bottom: 0;
  text-align: center;
  width: 100px;
  z-index: 10;
}

.Header__Wrapper {
  width: 100%;
  z-index: 11;
}

.Header__Container,
.Header__Mobile_Container {
  align-items: center;
  background-color: $primary-dark;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.24);
  display: flex;
  padding: 0 grid(4) 0 grid(2);
  position: relative;
}

.Header__Container {
  @media screen and (max-width: $larger) {
    padding-left: 9px;
    padding-right: 9px;
  }

  .Header__LinkContainer_Nav {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
  }

  .Header__LinkContainer_Dropdown {
    background-color: white;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    min-width: 300px;
    max-width: 335px;
    padding: 42px 0 24px;
    position: absolute;
    right: 24px;
    top: 81px;
    z-index: 1000;

    &:after {
      content: "";
      border-bottom: 10px solid white;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      right: 18px;
      position: absolute;
      top: -10px;
    }

    @media screen and (max-width: $larger) {
      right: 9px;
    }
  }
}

@mixin dropdown-text {
  color: $primary-dark !important;
  cursor: pointer;
  font-family: $inter-font-family;
  font-size: 18px;
  font-weight: 300;
  padding: 0 30px;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;

  @media screen and (max-width: $small) {
    padding-left: grid(3);
    padding-right: grid(3);
  }

  &:hover {
    color: $accent-dark-alt;
  }
}

.Header__Mobile_Container {
  flex-direction: column;
  justify-content: center;
  padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);

  .Header__LinkContainer_Dropdown {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    overflow: hidden;
    padding-top: 24px;
    position: relative;
    width: 100vw;
  }

  .Header__Link_Nav,
  .Header__Link_Dropdown {
    @include dropdown-text;
    align-items: center;
    display: flex;
    margin: 0;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 40px;
    max-height: 60px;
  }

  .Header__LoginButtonWrapper {
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .Header__LoginButton {
    @include undoDefaultButton;
    @include dropdown-text;
  }

  .Header__Link_Dropdown:last-child {
    margin-bottom: 24px;
  }

  .Header__ProfileLink {
    padding-left: 36px;
    padding-right: 36px;
    width: 100%;

    @media screen and (max-width: $small) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

.Header__LinkContainer_DropdownSpacer {
  flex-grow: 10;
  flex-shrink: 10;
}

.Header__LinkContainer_DropdownVersion {
  @include defaultBodyText;
  color: $light-grey;
  flex-grow: 5;
  flex-shrink: 0;
  font-size: 0.75rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  width: 100%;

  p {
    margin: 0;
  }
}

.Header__Brand {
  margin-top: grid(3);
  max-height: 62px;
  padding-bottom: grid(3);

  &:hover {
    polygon,
    path {
      fill: $accent-light;
    }
  }

  @include media($large) {
    margin-left: grid(2);
    margin-top: 0;
    max-height: 54px;
    padding-bottom: 0;
  }

  @include media-greater-than($large) {
    height: 100%;
    margin-left: grid(6);
  }

  @include media($medium) {
    margin-left: 5px;
    max-height: 36px;
  }
}

.Header__Mobile_Toggle {
  align-items: center;
  display: flex;
  min-height: $tablet-header-height;
  padding: env(safe-area-inset-top) grid(2.5) 0 0;
  width: 100%;

  @include media($medium) {
    min-height: $mobile-header-height;
  }
}

.Header__Mobile_Collapse {
  background: white;
  display: none;
  height: 100vh;
  overflow: hidden;
  position: relative;
  @include media($medium) {
    min-height: $mobile-header-height;
  }

  &--open {
    @extend .Header__Mobile_Collapse;
    display: block;
    margin-bottom: 1px;
    overflow: scroll;
    z-index: 1000;
  }
}

.MobileHeader__NotificationContainer {
  position: absolute;
  top: 0;
  right: 0;
}

.Header__Notification_Container {
  margin: 0 grid(3);
}

.Header__NotificationCount {
  @include font-medium;

  align-items: center;
  background: $red;
  border-radius: 25px;
  color: $white;
  display: flex;
  font-size: 0.625rem;
  font-stretch: normal;
  font-style: normal;
  justify-content: center;
  letter-spacing: 0.9px;
  line-height: 15px;
  max-height: 13px;
  padding: 2.5px 4.5px;
  position: absolute;
  top: 0;
  right: -9px;

  @include media($large) {
    top: 6px;
  }
}

.Header__InboxCount {
  @extend .Header__NotificationCount;
  @include media($large) {
    top: 0;
    right: 12px;
  }
}

.Header__InboxIcon {
  g path {
    stroke: $white;
  }
}

.Header__NotificationInner {
  align-items: center;
  display: flex;
  margin: 0 grid(3);
  position: relative;
  text-decoration: none;
  min-height: grid(
    4
  ); // Ensures that notification callouts are on the same plane

  @include media($large) {
    margin: 0;
  }
}

.Header__Collapse_Relative {
  position: relative;
  width: 100vw;
  height: 100%;
}

.Header__Collapse_ProfilePic {
  background-color: white;
  border-radius: 50%;
  border: 1px solid white;
  height: 50px;
  margin-right: 12px;
  max-width: 50px;
  width: 50px;
}

.Header__Profile {
  border-bottom: 1px solid transparentize(#979797, 0.5);
  margin-bottom: 15px;
  text-decoration: none;
  width: 100%;
}

.Header__ProfileLink {
  align-items: center;
  display: flex;
  padding: 0 30px 30px;
}

.Header__ProfileLinkContent {
  display: flex;
  flex-direction: column;
}

.Header__ProfileName {
  color: $primary-dark;
  font-family: $inter-font-family;
  font-size: 16px;
  font-weight: 700;
}

.Header__ProfileLinkCTA {
  color: $accent-dark;
  font-family: $inter-font-family;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.9px;
  line-height: normal;
  margin-top: 6px;
  text-decoration: none;
  text-transform: uppercase;
}

.Header__MobileCTAWrapper {
  display: flex;
  margin-left: auto;

  .Header__Notification_Count {
    top: 15px;
  }
}

.Header__Mobile_Hamburger {
  background: transparent;
  border: none;
  margin-left: 12px;
  outline: none;
  padding: 0;
  width: 30px; // fixed, so that icon doesn't jump when open/closed

  @media screen and (max-width: 380px) {
    margin-left: 0;
  }

  &:focus {
    outline: 0;
  }

  svg {
    path {
      stroke: $white;
    }
  }
}

.Header__Link_Nav {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-family: $inter-font-family;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.0135em;
  margin: 0 grid(4);
  outline: none;
  padding: 12px 0;
  text-decoration: none;
  text-transform: uppercase;

  @include media(1150px) {
    margin: 0 grid(2.5);
  }

  &:hover {
    color: $accent-light;
  }

  &--active {
    @extend .Header__Link_Nav;
    border-bottom: 2px solid white;
    font-weight: 600;
    padding: 0;
  }

  &#join {
    @extend .Header__Link_Nav;
    background-color: $accent-light-alt;
    border: 1px solid $accent-light-alt !important;
    color: $white;
    font-weight: 500;
    margin: 0 20px;
    padding: 9px 16px;

    &:hover {
      background-color: white;
      border: 1px solid $accent-dark !important;
      color: $accent-dark;
    }
  }
}

.Header__LinkBtn {
  border: 1px solid white;
  padding: 9px 16px;
  white-space: nowrap;

  @media screen and (max-width: $larger) {
    padding: 9px;
  }

  &:hover {
    border-color: $accent-light;
  }
}

.Header__Link_Dropdown {
  @include dropdown-text;
  margin: 18px 0;
}

.Header__Dropdown_Container {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 20px;

  @media screen and (max-width: $larger) {
    margin-left: 6px;
  }

  &:hover {
    img {
      border-color: $accent-light;
      background-color: $accent-light;
    }

    svg {
      fill: $accent-light;
    }
  }
}

.Header__Dropdown_Image {
  background-color: white;
  border-radius: 50%;
  border: 2px solid $primary-dark;
  height: 56px;
  max-height: 56px;
  max-width: 56px;
  min-height: 56px;
  min-width: 56px;
  width: 56px;

  @media screen and (max-width: $larger) {
    height: 52px;
    max-height: 52px;
    max-width: 52px;
    min-height: 52px;
    min-width: 52px;
    width: 52px;
  }
}

.Header__MobileFooterLabel {
  color: $light-grey;
  display: inline-block;
  font-family: $inter-font-family;
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
}

.Header__MobileJoinBtn {
  background-color: $accent-light-alt;
  border: 1px solid $accent-light-alt;
  margin: 0 20px;

  &:hover {
    background-color: white;
    border: 1px solid $accent-dark;
  }
}

.Header__InnerMobileJoinBtn {
  @include undoDefaultButton;
  text-transform: uppercase;
  padding: 5px 16px;
  width: 100%;
  height: 100%;
  color: $white;
  font-weight: 500;

  &:hover {
    color: $accent-dark;
  }
}

.Header__UltimateWrapper {
  align-items: center;
  display: flex;
}

.Header__Divider {
  background-color: $white;
  display: inline-block;
  height: 48px;
  margin: 0 grid(5) 0 grid(3);
  width: 1px;
}

.Header__LinkWrapper {
  position: relative;
}

.Header__NewTag {
  @include font-semi-bold;
  background-color: $accent-light-alt;
  color: $white;
  font-size: 0.7rem;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.76px;
  line-height: 1.3;
  padding: 0 grid(0.5) 0 grid(1);
  text-transform: uppercase;

  position: absolute;
  top: -16px;
  left: 15px;
}

.Header__Banner.Banner {
  text-align: center;
}

.Header__OpenAppBanner.Banner {
  background-color: $black;
  position: relative;
  z-index: 999999 !important;

  .Banner_Inner {
    align-items: center;
    display: flex;
    justify-content: space-between;

    a.Header__AppLink {
      background-color: $accent-dark-alt;
      border: 1px solid $accent-dark-alt;
      border-radius: 20px;
      color: $white;
      margin-right: grid(1);
      padding: grid(0.5) grid(2);
      text-decoration: none;
    }
  }
}

.Header__AppIcon {
  border: 1px solid $faint-grey;
  margin-left: grid(1);
  margin-right: grid(1);
  max-height: 30px;
  max-width: 30px;
}

// Testing Classes
.Parent {
  margin: 0;
  width: 96vw;

  .Header__Container,
  .Header__Mobile_Container,
  .Header__MobileFooterOuter {
    width: inherit;

    @media screen and (max-width: $large) {
      left: 0;
      width: 100%;
    }
  }
}
